@font-face {
  font-family: 'Circular';
  font-display: swap;
  src: url("/assets/fonts/circular/circular-book.woff2") format('woff2'),
    url("/assets/fonts/circular/circular-book.woff") format('woff');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Circular';
  font-display: swap;
  src: url("/assets/fonts/circular/circular-medium.woff2") format('woff2'),
    url("/assets/fonts/circular/circular-medium.woff") format('woff');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'Circular';
  font-display: swap;
  src: url("/assets/fonts/circular/circular-bold.woff2") format('woff2'),
    url("/assets/fonts/circular/circular-bold.woff") format('woff');
  font-style: normal;
  font-weight: 600;
}

/* ********************************************* */
/* ********************************************* */

@font-face {
  font-family: 'Inter';
  font-display: swap;
  src: url("/assets/fonts/Inter/Inter-Regular.woff2") format('woff2'),
    url("/assets/fonts/Inter/Inter-Regular.woff") format('woff');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Inter';
  font-display: swap;
  src: url("/assets/fonts/Inter/Inter-SemiBold.woff2") format('woff2'),
    url("/assets/fonts/Inter/Inter-SemiBold.woff") format('woff');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Inter';
  font-display: swap;
  src: url("/assets/fonts/Inter/Inter-Bold.woff2") format('woff2'),
    url("/assets/fonts/Inter/Inter-Bold.woff") format('woff');
  font-style: normal;
  font-weight: bold;
}

/* ********************************************* */
/* ********************************************* */

/* // Hebrew fonts */
@font-face {
  font-family: 'Assistant';
  font-display: swap;
  src: url("/assets/fonts/Assistant/Assistant-SemiBold.ttf") format('truetype');
  font-style: normal;
  unicode-range: 'U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F';
}

@font-face {
  font-family: 'Rubik';
  font-display: swap;
  src: url("/assets/fonts/Rubik/Rubik-Regular.ttf") format('truetype');
  font-style: normal;
  unicode-range: 'U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F';
}
